import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ThemeContext } from "../App";

import Contacts from "../components/Contacts";
import Form from "../components/Form";

import styles from "./Home.module.scss";

import mainBg from "../assets/mainPage_bg.png";
import heart from "../assets/heart_blue.png";
import emoji from "../assets/emoji_blue.png";
import flag1 from "../assets/isflag1.png";
import flag2 from "../assets/isflag2.png";
import Loader from "../components/Loader";
import Greetings from "../components/greetings/Greetings";

import mainBg_onlyBlue from "../assets/mainBg_onlyBlue.png";
import mainBg_notebook from "../assets/mainBg_noteBook.png";
import mainBg_iphone from "../assets/iphone-15-pro-quality.png";

import rabbit from "../assets/rabbit.png";
import interier from "../assets/interier_card.png";
import neon from "../assets/neon.png";
import car from "../assets/car.png";
import woman from "../assets/black_wo.png";
import exterier from "../assets/exterier.png";
import learn from "../assets/learn.png";
import plan from "../assets/plan.png";
import tomFord from "../assets/tomFord.png";
import art from "../assets/art_object.png";
import brochures from "../assets/brochures.png";
import hub from "../assets/inhub.png";
import swiss from "../assets/swiss_army.png";
import internet from "../assets/internet3g.png";
import visual3d from "../assets/3dbot1.png";
import Gravity_Card from "../assets/Gravity-Card.png";
import girl_comp from "../assets/girl_comp.png";
import calendar from "../assets/calendar.png";
import liitle_pen from "../assets/liitle_pen.png";
import card_corona from "../assets/card_corona.png";
import art_design from "../assets/art_design.png";
import cans from "../assets/2_cans.png";
import cats from "../assets/cats.png";
import strategy from "../assets/STRATEGY.png";
import design from "../assets/DESIGN.png";
import technology from "../assets/TECHNOLOGY.png";

// import reserve from '../assets/reserve.png';

const Home = () => {
	const imageList = [
		{
			image: rabbit,
			style: styles.item1,
			link: "/logos/rabbit",
			category: "Logos",
		},
		{
			image: interier,
			style: styles.item2,
			link: "/3dvisualization/interior",
			category: "3D visualization",
		},
		{
			image: neon,
			style: styles.item3,
			link: "/animation/linq",
			category: "Animation",
		},
		{
			image: woman,
			style: styles.item4,
			link: "/retouching",
			category: "Retouching",
		},
		{
			image: visual3d,
			style: styles.item5,
			link: "/3dvisualization/park",
			category: "3D visualization",
		},
		{
			image: cans,
			style: styles.item6,
			link: "/logos/package",
			category: "Logos",
		},
		{
			image: plan,
			style: styles.item7,
			link: "/3dvisualization/architecture",
			category: "3D visualization",
		},
		{
			image: internet,
			style: styles.item8,
			link: "/animation/nexign",
			category: "Animation",
		},
		{
			image: art_design,
			style: styles.item9,
			link: "/logos/poster",
			category: "Logos",
		},
		{
			image: card_corona,
			style: styles.item10,
			link: "/animation/koronapay",
			category: "Animation",
		},
		{
			image: cats,
			style: styles.item11,
			link: "/animation/startup",
			category: "Animation",
		},
	];
	// Translation
	const { t } = useTranslation();
	const [innerWidth, setInnerWidth] = useState(window.innerWidth);
	const { theme } = useContext(ThemeContext);
	// <Greetings/>

	useEffect(() => {
		const handleResize = () => {
			setInnerWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const showIphoneImage = innerWidth < 800;

	return (
		<div className={styles.home_wrapper}>
			{/* <div class={styles.background_image}></div> */}
			<img className={styles.mainBg} src={mainBg_onlyBlue} alt="mainBg" />
			{showIphoneImage ? (
				<img
					className={styles.mainBg__iphone}
					src={mainBg_iphone}
					alt="mainBg_iphone"
				/>
			) : (
				<img
					className={styles.mainBg__notebook}
					src={mainBg_notebook}
					alt="mainBg_notebook"
				/>
			)}
			<div
				className={`${styles.header_wrapper} ${
					theme === "reversed" ? styles.header_wrapper_reversed : ""
				}`}
			>
				{/* <h3 className={`${styles.title1} ${theme === 'reversed' ? styles.title1_reversed : ''}`}>
          STRATEGY
        </h3> */}
				<div className={styles.header__strategy}>
					<img src={strategy} alt="strategy" />
				</div>
				<br />
				{/* <h3
          className={`${styles.title2} ${theme === 'reversed' ? styles.title2_reversed : ''}`}>
          DESIGN
        </h3> */}
				<div className={styles.header__design}>
					<img src={design} alt="design" />
				</div>
				<br />
				{/* <h3 className={`${styles.title3} ${theme === 'reversed' ? styles.title3_reversed : ''}`}>
          TECHNOLOGY
        </h3> */}
				<div className={styles.header__technology}>
					<img src={technology} alt="technology" />
				</div>
				<span className={styles.flare}></span>

				{/* <img className={styles.flag1} src={flag1} alt="flag1_icon" /> */}
			</div>
			<p
				className={`${styles.text} ${
					theme === "reversed" ? styles.text_reversed : ""
				}`}
			>
				{t("home_text")}
			</p>

			<section className={styles.portfolio}>
				<div className={styles.portfolio__header}>Portfolio</div>
				<div className={styles.portfolioCards}>
					{imageList.map((item) => (
						<Link key={item.image} to={item.link} className={item.style}>
							<img src={item.image} alt={item.image} />
							<span>Cases</span>
						</Link>
					))}
				</div>
			</section>

			<div className={styles.form}>
				<Form />
				<Contacts />
			</div>
			{/* <img className={styles.flag2} src={flag2} alt="flag2_icon" /> */}
		</div>
	);
};

export default Home;
