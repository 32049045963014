import React from "react";
import styles from "./Greetings.module.scss";
import { useState, useRef } from "react";
import { useSpring, animated, config } from "@react-spring/web";
import { useEffect } from "react";

const Greetings = () => {
	const [animate, setAnimate] = useState(false);
	const [showAnimation, setShowAnimation] = useState(true);
	const [block, setBlock] = useState(true);

	const textRef = useRef(null); // Создаем ref для текстового элемента

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowAnimation(false);
		}, 2000); // Задержка в 2 секунды перед скрытием
		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		const hasShownAnimation = localStorage.getItem("hasShownAnimation");

		const timerForBlock = setTimeout(() => {
			setBlock(false);
			enableScroll(); // После завершения анимации разблокировать прокрутку
		}, 4000);
		if (!hasShownAnimation) {
			// Заблокировать прокрутку при монтировании компонента
			disableScroll();
		}

		return () => clearTimeout(timerForBlock);
	}, []);

	// Функция для блокировки прокрутки
	function disableScroll() {
		document.body.style.overflow = "hidden";
	}

	// Функция для разблокировки прокрутки
	function enableScroll() {
		document.body.style.overflow = "auto";
	}

	// Получаем высоту текстового элемента с использованием getBoundingClientRect()
	const textHeight = textRef.current
		? textRef.current.getBoundingClientRect().height
		: 0;

	const textAnimation = useSpring({
		transform: showAnimation ? "translateY(50%)" : "translateY(240%)",
		config: { duration: 1000 }, // Настройки анимации текста
		from: { opacity: 1, transform: "translateY(-100%)" }, // Начальное состояние для плавного появления
		delay: showAnimation ? 0 : 1000, // Задержка перед исчезновением
	});

	const wrapperAnimation = useSpring({
		opacity: showAnimation ? 1 : 0,
		display: block ? "block" : "none",
		config: { duration: 800 }, // Настройки анимации фона
		from: { opacity: 1 }, // Начальное состояние для плавного появления
		delay: showAnimation ? 0 : 1800,
	});

	useEffect(() => {
		// Проверьте, была ли анимация уже показана ранее
		const hasShownAnimation = localStorage.getItem("hasShownAnimation");

		if (!hasShownAnimation) {
			console.log("setTrue");
			// Если анимация еще не была показана, установите флаг и покажите анимацию
			localStorage.setItem("hasShownAnimation", "true");
			setAnimate(true);
		}
	}, []);

	return (
		<>
			{animate && (
				<div className="fixed-top">
					<animated.div style={wrapperAnimation} className={styles.wrapper}>
						<animated.div style={textAnimation} className={styles.text}>
							<div ref={textRef} className={styles.text}>
								We build successful <br /> digital projects <br />
								for ambitious brands
							</div>
						</animated.div>
					</animated.div>
				</div>
			)}
		</>
	);
};

export default Greetings;
