import React from "react";
import { Link } from "react-router-dom";

import styles from "./Icons.module.scss";

import insta from "../assets/instagram-svgrepo-com.svg";
import whatsapp from "../assets/whatsapp-svgrepo-com.svg";
import telegram from "../assets/telegram-plane-svgrepo-com.svg";

const Icons = () => {
	return (
		<div className={styles.iconsWrapper}>
			<a
				className={styles.icon}
				href="https://www.instagram.com/designisreall/"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img src={insta} alt="icon" />
			</a>
			<a
				className={styles.icon}
				href="https://wa.me/972533978796"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img src={whatsapp} alt="icon" />
			</a>
			<a
				className={styles.icon}
				href="https://t.me/designisrealbot "
				target="_blank"
				rel="noopener noreferrer"
			>
				<img src={telegram} alt="icon" />
			</a>
		</div>
	);
};

export default Icons;
