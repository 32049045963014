import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";
import styles from "./Carousel.module.scss";

import cans from "../../assets/2_cans.png";
import cards from "../../assets/Gravity-Card.png";
import design from "../../assets/art_design.png";
import brochures from "../../assets/brochures.png";
import calendar from "../../assets/calendar.png";
import rabbit from "../../assets/rabbit.png";
import poko from "../../assets/poko_icon.png";
import canyonLogo from "../../assets/canyonLogo_icon.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

// const TopImages = [
// 	{ url: cans, name: "/logos/package" },
// 	{ url: cards, name: "/logos/videomaker" },
// 	{ url: design, name: "/logos/poster" },
// 	{ url: poko, name: "/logos/asiancafe" }, //тут остановился
// ];
// const BottomImages = [
// 	{ url: brochures, name: "/logos/vinyl" },
// 	{ url: calendar, name: "/logos/dali" },
// 	{ url: rabbit, name: "/logos/rabbit" },
// 	{ url: canyonLogo, name: "/logos/canyon" },
// ];

const Carousel = ({ arrayOfPhoto, speedCount }) => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		// Обработчик изменения размера окна
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		// Добавление слушателя события изменения размера окна
		window.addEventListener("resize", handleResize);

		// Удаление слушателя события при размонтировании компонента
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []); // Пустой массив зависимостей означает, что эффект выполняется только после монтирования компонента

	return (
		<>
			{windowWidth > 620 ? (
				<div className={styles.wrapper}>
					<Splide
						options={{
							type: "loop",
							gap: "10px",
							drag: "free",
							arrows: false,
							pagination: false,
							perPage: 3,
							autoScroll: {
								pauseOnHover: false,
								pauseOnFocus: false,
								rewind: false,
								speed: speedCount,
							},
						}}
						extensions={{ AutoScroll }}
					>
						{[...arrayOfPhoto].map((photo, index) => (
							<SplideSlide key={index} className={styles.slide}>
								<Link to={photo.name} className={styles.imgWrapper}>
									<img src={photo.url} alt="Image_2" />
								</Link>
							</SplideSlide>
						))}
					</Splide>
				</div>
			) : (
				<div className={styles.staticWrapper}>
					{[...arrayOfPhoto].map((photo, index) => (
						<div key={index}>
							<Link to={photo.name} className={styles.imgStaticWrapper}>
								<img src={photo.url} alt="Image_1" />
							</Link>
						</div>
					))}
				</div>
			)}
		</>
	);
};

export default Carousel;
