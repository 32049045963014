import React from "react";
import { useTranslation } from "react-i18next";
import Contacts from "../components/Contacts";
import Form from "../components/Form";
import ReactPlayer from "react-player";

import bg_1 from "../assets/linq_bg_1.png";
import bg_2 from "../assets/linq_bg_2.png";
import bg_3 from "../assets/linq_bg_3.png";
import bg_4 from "../assets/linq_bg_4.png";
import bg_5 from "../assets/linq_bg_5.png";

import styles from "./Linq.module.scss";

import headerImg from "../assets/Linq.main.png";

const Linq = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.wrapper}>
			<section className={styles.topSection}>
				<div className={styles.image}>
					<img src={headerImg} alt="bgImage" />
					<h1>LINQ</h1>
				</div>
			</section>
			<section className={styles.description}>
				<div className={styles.bgOne__wrapper}>
					<img className={styles.bg_one} src={bg_1} alt="bgImage" />
					<p className={styles.topText}>
						Based on the client concept and the brandbook of the conference, we
						have developed a recognisable design style using 3D and 2D graphics
						and motion-design. <br />
						<br /> This style was inspired by a Tetris-shaped Rubik's cube
						symbolizing both variability and connection between all the
						directions (that) LINQ unites on one site.
					</p>
				</div>

				<div className={styles.bgTwo__wrapper}>
					<img className={styles.bg_one} src={bg_2} alt="bgImage" />
				</div>

				<div className={styles.bgThree__wrapper}>
					<img className={styles.bg_one} src={bg_3} alt="bgImage" />
					<p className={styles.bottomText}>
						Organizers of the conference divided the entire territory into
						several zones, such as the main stage zone, the networking zone, the
						workshop zone and others.
					</p>
				</div>

				<div className={styles.bgThree__wrapper}>
					<img className={styles.bg_one} src={bg_4} alt="bgImage" />
				</div>

				<div className={styles.bgThree__wrapper}>
					<img className={styles.bg_one} src={bg_5} alt="bgImage" />
					<p className={styles.bottomText}>
						As a result, in less than 3 weeks more than 80 pieces of content
						were prepared for each zone, including speaker presentations, report
						topics and statements of the event sponsors.
					</p>
				</div>

				{/* <div className={styles.videoWrapper}>
					<ReactPlayer
						url="https://vimeo.com/239242832"
						width="100%"
						height="100%"
						// controls
						// config={{ vimeo: { playerOptions: { background: true } } }}
						config={{
							vimeo: {
								playerOptions: { byline: true, controls: true },
							},
						}}
						className={styles.video}
					/>
				</div> */}
			</section>
			<div className={styles.form}>
				<Form />
				<Contacts />
			</div>
		</div>
	);
};

export default Linq;
