import React from 'react'
import { useTranslation } from 'react-i18next';

import styles from './AsianCafe.module.scss';

import Contacts from '../components/Contacts';
import Form from '../components/Form';

import headerImg from '../assets/poke_mainImg.png';
import yellowSquare from '../assets/poke_yellowSquare.png';
import greenSquare from '../assets/poke_greenSquare.png';
import decision from '../assets/poke_decision.png';
import res_1 from '../assets/poke_res_1.png';
import res_2 from '../assets/poke_res_2.png';
import res_3 from '../assets/poke_res_3.png';
import res_4 from '../assets/poke_res_4.png';
import res_5 from '../assets/poke_res_5.png';
import res_6 from '../assets/poke_res_6.png';
import res_7 from '../assets/poke_res_7.png';
import res_8 from '../assets/poke_res_8.png';
import res_9 from '../assets/poke_res_9.png';

const AsianCafe = () => {
  const { t } = useTranslation();
  
  return (
    <div className={styles.wrapper}>
      <section className={styles.topSection}>
        <div className={styles.image}>
          <img src={headerImg} alt="bgImage" />
          <h1>Logo for Asian cafe</h1>
        </div>
      </section>
      <section className={styles.description}>
        <h3 className={styles.subheader}>{t('sturm_subtitle')}</h3>
        <article>
          <p>Create a cute, simple logo for a cafe with Asian cuisine. The main dish is poké.</p>
          <br /> <br />
          <p>The customer asked to use a natural color palette, but at the same time to make it look bright.</p>
        </article>

        <div className={styles.topWrapper}>
          <img src={greenSquare} alt="square" />
          <img src={yellowSquare} alt="squre" />
          <div className={styles.imageText}>
          <p>color palette</p>
          </div>
        </div>

        <h3 className={styles.decision}>Decision</h3>
        <article>
          <p>Thus, we decided to depict the main dish of this cafe on the logo.</p>
          <br /><br />
          <p>We took only two colors - green to convey the freshness and naturalness of the products and orange to represent the main ingredient of poké - shrimp.</p>
        </article>

          <div className={styles.longImg}>
            <img src={decision} alt="decision" />
          </div>

        <h3 className={styles.subheader}>{t('servPage_result')}</h3>
          <div className={styles.resWrapper}>
            <img src={res_1} alt="img" />
            <img src={res_2} alt="img" />
            <img src={res_3} alt="img" />
            <img src={res_4} alt="img" />
            <img src={res_5} alt="img" />
            <img src={res_6} alt="img" />
            <img src={res_7} alt="img" />
            <img src={res_8} alt="img" />
            <img src={res_9} alt="img" />
          </div>
      </section>
      <div className={styles.form}>
        <Form />
        <Contacts />
      </div>
    </div>
  )
}

export default AsianCafe