import React from 'react'
import { useTranslation } from 'react-i18next';

import styles from './Canyon.module.scss';

import Contacts from '../components/Contacts';
import Form from '../components/Form';

import headerImg from '../assets/canyon_mainBg.png';
import blackSquare from '../assets/canyon_black.png';
import yellowSquare from '../assets/canyon_yellow.png';
import whiteSquare from '../assets/canyon_white.png';
import decisionImg1 from '../assets/canyon_decision_1.png';
import decisionImg2 from '../assets/canyon_decision_2.png';
import resImg1 from '../assets/canyon_res_1.png';
import resImg2 from '../assets/canyon_res_2.png';
import resImg3 from '../assets/canyon_res_3.png';
import resImg4 from '../assets/canyon_res_4.png';
import decision from '../assets/poke_decision.png';

const Canyon = () => {
    const { t } = useTranslation();
    
  return (
    <div className={styles.wrapper}>
      <section className={styles.topSection}>
        <div className={styles.image}>
          <img src={headerImg} alt="bgImage" />
          <h1>Logo for the construction company</h1>
        </div>
      </section>

      <section className={styles.description}>
        <h3 className={styles.subheader}>{t('sturm_subtitle')}</h3>
        <article>
          <p>We had a task to create a stylish logo for an already quite large company producing facade panels.</p>
          <br /> <br />
          <p>To make the logo recognizable and interesting, using only the same color as in the old logo.</p>
        </article>

        <div className={styles.topWrapper}>
          <img src={blackSquare} alt="square" />
          <img src={yellowSquare} alt="squre" />
          <img src={whiteSquare} alt="squre" />
          <div className={styles.imageText}>
          <p>color palette</p>
          </div>
        </div>

         <h3 className={styles.subheader}>Decision</h3>
        <article>
          <p>Grand Canyon means longevity, steadfastness and strength.</p>
          <br /> <br />
          <p>These are the qualities that the company that approached us possesses, so it was decided to depict the canyon as the main symbol.</p>
          <br />
          <p>Our team offered many different logo options. </p>
        </article>
        <div className={styles.decisionImg}>
          <img src={decisionImg1} alt="decision" />
           <img src={decisionImg2} alt="decision" />
        </div>

        <h3 className={`${styles.resultSubheader} ${styles.subheader}`}>{t('servPage_result')}</h3>
        <p className={styles.resText}>The customer settled on one option, which he is using now.</p>
        <div className={styles.resBg}>
          <img src={resImg1} alt="resImg1" />
        </div>
        <div className={styles.resBgBottom}>
          <div className={styles.resBgBottom__top}>
            <img src={resImg2} alt="resImg2" />
             <img src={resImg3} alt="resImg3" />
          </div >
           <img className={styles.resBgBottom__bot} src={resImg4} alt="resImg4" />
        </div>
      </section>
            <div className={styles.form}>
        <Form />
        <Contacts />
      </div>
    </div>
  )
}

export default Canyon