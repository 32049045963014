import React from 'react'
import { useTranslation } from 'react-i18next';

import Contacts from '../components/Contacts';
import Form from '../components/Form';

import styles from './CanyonWebsite.module.scss';
import mainBg from '../assets/canyon_web_mainBg.png';
import screen1 from '../assets/canyon_web_secreen1.png';
import screen2 from '../assets/canyon_web_secreen2.png';
import screen3 from '../assets/canyon_web_secreen3.png';
import screen4 from '../assets/canyon_web_secreen4.png';

const CanyonWebsite = () => {
    const { t } = useTranslation();

  return (
      <div className={styles.wrapper}>
      <section className={styles.topSection}>
        <div className={styles.image}>
          <img src={mainBg} alt="bgImage" /* className={styles.image} */ />
        </div>
    
        <h1 className={styles.header}>Website for “Canyon” company</h1>
      </section>
      <section className={styles.description}>
        <h3>{t('sturm_subtitle')}</h3>
        <p className={styles.taskText}>The task was to create a website for a company engaged in the production of facade panels. The site should include many sections, each of which was negotiated with the customer.</p>
        
        <h3>Process</h3>
        <div className={styles.processExample}>
          <img src={screen1} alt="screen1" />
          <p>A product card was developed for each product collection</p>
        </div>
        <div className={styles.processExample}>
          <img src={screen2} alt="screen2" />
          <p>Portfolio page</p>
        </div>
        <div className={styles.processExample}>
          <img src={screen3} alt="screen3" />
          <p>Section - "about the company"</p>
        </div>

        <h3>{t('servPage_result')}</h3>
        <img className={styles.bottomImage} src={screen4} alt="bottomImg" />
      </section>
      <div className={styles.form}>
        <Form />
        <Contacts />
      </div>
    </div>
  )
}

export default CanyonWebsite